import React, { forwardRef, useState } from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import { useNavigate } from "react-router-dom";

import { faSearch as searchIcon } from "@fortawesome/free-solid-svg-icons/faSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAlgolia } from "../hooks/useAlgolia";

import { AutoComplete } from "./AutoComplete";
import { TripSearchResultWithLink } from "./trip/TripSearchResult";
import { UserSearchResultWithLink } from "./user/UserSearchResult";

const UniversalSearchContainer = forwardRef(function UniversalSearchContainer(
  { isEmpty, children, ...props },
  ref,
) {
  return (
    <div
      ref={ref}
      className={
        "flex absolute left-0 w-full mt-1 shadow bg-cardBackgroundColor rounded-lg z-50"
      }
      {...props}
      key="thisismykey"
    >
      {children}
    </div>
  );
});

const UniversalSearchResult = ({ data, isHighlighted }) => {
  const ResultComponent = (() => {
    switch (data.indexType) {
      case "user":
        return UserSearchResultWithLink;
      case "trip":
      case "story":
      case "preview":
        return TripSearchResultWithLink;
      default:
        return null;
    }
  })();

  if (!ResultComponent) return null;

  return (
    <div
      key={data.objectID}
      className={`cursor-pointer ${isHighlighted ? "bg-dividerColor highlighted" : ""}`}
    >
      <ResultComponent data={data} isHighlighted={isHighlighted} />
    </div>
  );
};

const adminPathForObject = (suggestion) => {
  switch (suggestion.indexType) {
    case "user":
      return `/${suggestion.objectID.replace(":", "s/")}`;
    case "trip":
      return `/${suggestion.objectID.replace("trip", "tripRequest").replace(":", "s/").replace(/:/g, "/")}`;
    case "preview":
      return `/${suggestion.objectID.replace("preview", "tripRequest").replace(":", "s/").replace(/:/g, "/")}`;
    case "story":
      return `/${suggestion.objectID.replace("story", "tripRequest").replace(":", "s/").replace(/:/g, "/")}/story`;
    default:
      return "/";
  }
};

export const UniversalSearchFilter = {
  trip: {
    name: "Trip Requests",
    value: "trip",
  },
  story: {
    name: "Full Stories",
    value: "story",
  },
  preview: {
    name: "Trip Previews",
    value: "preview",
  },
  user: {
    name: "Users",
    value: "user",
  },
};

const UniversalSearchFilters = ({ selectedFilter, setSelectedFilter }) => {
  return (
    <ul className="flex mr-2">
      {Object.values(UniversalSearchFilter).map(({ value, name }, index) => {
        const roundedFirst = index === 0 ? "rounded-l" : "";
        const roundedLast =
          index === Object.values(UniversalSearchFilter).length - 1
            ? "rounded-r"
            : "";

        return (
          <li className="flex items-center" key={value}>
            <input
              id={`${value}-option`}
              type="radio"
              checked={selectedFilter === value}
              onChange={() => setSelectedFilter(value)}
              className="form-checkbox hidden peer"
            />
            <label
              htmlFor={`${value}-option`}
              className={`flex border border-dividerColor px-2 h-10 text-center align-middle items-center text-sm leading-4
              ${selectedFilter === value ? "bg-dividerColor" : ""}
              ${roundedFirst} ${roundedLast}
            `}
            >
              {name}
            </label>
          </li>
        );
      })}
    </ul>
  );
};

export const UniversalSearch = () => {
  const navigate = useNavigate();
  const algolia = useAlgolia();
  const [selectedFilter, setSelectedFilter] = useState(
    UniversalSearchFilter.trip.value,
  );

  return (
    <div className="w-full flex">
      <UniversalSearchFilters
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      <div className="w-full">
        <InstantSearch
          searchClient={algolia}
          indexName={`${process.env.ALGOLIA_INDEX_PREFIX}admin_universal`}
          future={{
            preserveSharedStateOnUnmount: true,
          }}
        >
          <Configure
            hitsPerPage={21}
            filters={`indexType:${selectedFilter}`}
            attributesToSnippet={["blocks", "questionnaires"]}
          />
          <AutoComplete
            className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 pl-10 pr-2 block w-full appearance-none leading-normal ds-input"
            placeholder="Universal search (Press / to focus)"
            ResultComponent={UniversalSearchResult}
            ContainerComponent={UniversalSearchContainer}
            postHogEvent="selected_universalsearch_result"
            onSelect={(suggestion) => {
              navigate(adminPathForObject(suggestion));
            }}
            theme={{
              suggestionsList: "grid grid-cols-3 gap-2",
            }}
            onKeyDown={(event) => {
              const {
                key,
                target: { value },
              } = event;
              if (key === "Enter") {
                navigate(`/search?q=${value}&filter=${selectedFilter}`);
                event.preventDefault();
              }
            }}
          />
          <div className="pointer-events-none absolute ml-4 -mt-8">
            <FontAwesomeIcon
              icon={searchIcon}
              className="text-textDimmedColor"
            />
          </div>
        </InstantSearch>
      </div>
    </div>
  );
};
