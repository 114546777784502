export const isPaymentBlock = block =>
  block.type === 'Card' && block.info && block.info.card && block.info.card.type === 'Payment';

export const isInAppPaymentBlock = block => block.type === 'InAppPayment';

export const sectionHeaderDateHeaderBlockIndex = 1;
export const sectionHeaderHeaderBlockIndex = 2;
export const isSectionHeader = block => {
  return (
    block?.type === 'Group' &&
    block?.info?.kind === 'SectionHeader' &&
    block?.info?.blocks?.[sectionHeaderDateHeaderBlockIndex]?.type === 'DateHeader' &&
    block?.info?.blocks?.[sectionHeaderDateHeaderBlockIndex]?.info?.startDate &&
    block?.info?.blocks?.[sectionHeaderDateHeaderBlockIndex]?.info?.endDate &&
    block?.info?.blocks?.[sectionHeaderHeaderBlockIndex]?.type === 'Header'
  );
};

export const isHeader = block => {
  return block?.type === 'Header';
};
