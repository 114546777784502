import React, { forwardRef, useMemo, useState } from "react";
import {
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";

import { faSearchLocation as previewIcon } from "@fortawesome/free-solid-svg-icons/faSearchLocation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Configure, InstantSearch } from "react-instantsearch";
import { firestore } from "../firebase";

import { ColumnTitle } from "./ColumnTitle";
import { Layout, LayoutColumn } from "./Layout";
import { PageTitle } from "./PageTitle";

import {
  collection,
  collectionGroup,
  doc,
  limit,
  orderBy,
  query,
  runTransaction,
} from "firebase/firestore";
import { useAlgolia } from "../hooks/useAlgolia";
import { AutoComplete } from "./AutoComplete";
import { Transaction } from "./Transaction";
import { TripSearchResult } from "./trip/TripSearchResult";
import { UserSearchResult } from "./user/UserSearchResult";

const SearchResult = ({ data, isHighlighted }) => {
  const ResultComponent = (() => {
    switch (data.indexType) {
      case "user":
        return UserSearchResult;
      case "trip":
        return TripSearchResult;
      default:
        return null;
    }
  })();

  if (!ResultComponent) return null;

  return (
    <div
      key={data.objectID}
      className={`cursor-pointer ${isHighlighted ? "bg-dividerColor highlighted" : ""}`}
    >
      <ResultComponent data={data} isHighlighted={isHighlighted} />
    </div>
  );
};

const SearchContainer = forwardRef(function SearchContainer(
  { isEmpty, children, ...props },
  ref,
) {
  return (
    <div
      ref={ref}
      className={
        "mt-1 shadow bg-cardBackgroundColor rounded-lg overflow-hidden"
      }
      {...props}
    >
      {children}
    </div>
  );
});

const AssignTransactionToTrip = ({ transactionId }) => {
  const algolia = useAlgolia();

  const copyTripPreviewInSuggestion = async (suggestion) => {
    if (
      // eslint-disable-next-line no-restricted-globals,no-alert
      !confirm(
        `Are you sure you want to assign this transaction to ${suggestion.title}?`,
      )
    )
      return;

    const splitSourceId = suggestion.objectID.split(":");
    if (splitSourceId.length !== 3)
      throw new Error(
        "Error please retry or contact eng; Details: id split length",
      );

    const tripRequestId = splitSourceId[1];
    const tripId = splitSourceId[2];

    try {
      await runTransaction(firestore, async (transaction) => {
        const sourceReference = doc(
          collection(firestore, "transactions"),
          transactionId,
        );
        const sourceSnapshot = await transaction.get(sourceReference);
        const destinationReference = doc(
          collection(
            doc(
              collection(
                doc(collection(firestore, "tripRequests"), tripRequestId),
                "trips",
              ),
              tripId,
            ),
            "transactions",
          ),
          transactionId,
        );
        await transaction.set(destinationReference, sourceSnapshot.data());
      });
    } catch (err) {
      // eslint-disable-next-line no-alert
      alert(err.message);
    }
  };

  return (
    <div className="relative z-40 mt-3">
      <InstantSearch
        searchClient={algolia}
        indexName={`${process.env.ALGOLIA_INDEX_PREFIX}admin_universal`}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure filters="indexType:trip" hitsPerPage={10} />
        <AutoComplete
          className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 pl-10 pr-2 block w-full appearance-none leading-normal ds-input"
          placeholder="Choose Trip to Assign"
          ResultComponent={SearchResult}
          ContainerComponent={SearchContainer}
          postHogEvent="copy_suggested_trip_preview_from_transaction_page"
          onSelect={(suggestion) => copyTripPreviewInSuggestion(suggestion)}
        />
      </InstantSearch>
      <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
        <FontAwesomeIcon icon={previewIcon} className="text-textDimmedColor" />
      </div>
    </div>
  );
};

export const AllTransactionsPage = () => {
  const reference = useMemo(
    () =>
      query(
        collectionGroup(firestore, "transactions"),
        orderBy("addedAt", "asc"),
        limit(100),
      ),
    [],
  );
  const [transactions] = useCollectionData(reference, { idField: "id" });
  console.log(transactions);

  return (
    <div className="flex-grow p-3 m-3 bg-cardBackgroundColor overflow-y-auto grid content-start gap-2 shadow rounded">
      {transactions && transactions.length > 0 ? (
        transactions.map((transaction) => (
          <Transaction key={transaction.id} transactionMetadata={transaction} />
        ))
      ) : (
        <div> No Transactions!</div>
      )}
    </div>
  );
};

export const UnassignedTransactionsPage = () => {
  const reference = useMemo(
    () =>
      query(
        collection(firestore, "transactions"),
        orderBy("addedAt", "asc"),
        limit(100),
      ),
    [],
  );
  const [transactions] = useCollection(reference);

  return (
    <div className="flex-grow p-3 m-3 bg-cardBackgroundColor overflow-y-auto grid content-start gap-2 shadow rounded">
      {transactions?.docs && transactions?.docs.length > 0 ? (
        transactions?.docs.map((transaction) => (
          <Transaction
            key={transaction.id}
            transactionMetadata={transaction.data()}
            assignTransactionToTripComponent={
              <AssignTransactionToTrip transactionId={transaction.id} />
            }
          />
        ))
      ) : (
        <div> No Unassigned Transactions!</div>
      )}
    </div>
  );
};

export const OpenTransactionsPage = () => {
  const [listToShow, setListToShow] = useState(0);

  return (
    <>
      <PageTitle title="Open Messages" />
      <Layout className="flex-grow" titleComponent={ColumnTitle}>
        <LayoutColumn relativeWidth={1} title="Filters">
          <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow text-sm">
            <h2 className="text-lg font-semibold mb-2">Show</h2>
            <div className="pr-10 flex py-1">
              <input
                type="radio"
                style={{}}
                checked={listToShow === 0}
                onChange={() => setListToShow(0)}
              />
              <div className="pl-1">Unassigned Transactions</div>
            </div>
            <div className="flex py-1">
              <input
                type="radio"
                style={{}}
                checked={listToShow === 1}
                onChange={() => setListToShow(1)}
              />
              <div className="pl-1">All Transactions</div>
            </div>
          </div>
        </LayoutColumn>
        <LayoutColumn
          relativeWidth={4}
          title="Transactions Overview"
          compactTitle="Transactions"
        >
          {listToShow === 0 && <UnassignedTransactionsPage />}
          {listToShow === 1 && <AllTransactionsPage />}
        </LayoutColumn>
      </Layout>
    </>
  );
};
