import {
  faSignInAlt as goToIcon,
  faObjectGroup as selectIcon,
  faList as tableOfContentsIcon,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import {
  isHeader,
  isSectionHeader,
  sectionHeaderHeaderBlockIndex,
} from "../../helpers/blocks";
import { SelectedBlocksContext } from "../wysiwyg/useSelectedBlocks";

export const TripTableOfContents = ({ blocks, setGoToBlockUuid }) => {
  const { calculateSelectedIndexRange, selectedBlocks, setSelectedBlocks } =
    useContext(SelectedBlocksContext);

  const eligibleBlocks = blocks.filter(
    (block) => isSectionHeader(block) || isHeader(block),
  );

  const handleSectionSelect = (sectionHeaderIndex, withShift = false) => {
    const blockUuids = withShift ? selectedBlocks.uuids || {} : {};
    const nextSectionHeaderIndex = sectionHeaderIndex + 1;
    let startBlockFound = false;
    blocks.forEach((block, index) => {
      if (block.uuid === eligibleBlocks[sectionHeaderIndex].uuid) {
        startBlockFound = true;
      }
      if (block.uuid === eligibleBlocks[nextSectionHeaderIndex]?.uuid) {
        startBlockFound = false;
      }
      if (startBlockFound) {
        blockUuids[block.uuid] = index;
      }
    });
    const { minIndex, maxIndex } = calculateSelectedIndexRange(blockUuids);
    const updatedValue = { minIndex, maxIndex, uuids: blockUuids };
    setSelectedBlocks(updatedValue);
  };

  const handleGoToBlock = (uuid) => {
    if (uuid) {
      setGoToBlockUuid(uuid);
    }
  };

  const getTitle = (block) => {
    if (isSectionHeader(block)) {
      return block.info.blocks[sectionHeaderHeaderBlockIndex].info.title;
    }
    if (isHeader(block)) {
      return block.info.title;
    }
    return block.uuid;
  };

  const isSelected = (block) => {
    return selectedBlocks.uuids[block.uuid] !== undefined;
  };

  return (
    <div className="flex flex-col bg-cardBackgroundColor mt-3 p-3 rounded shadow">
      <div className="flex items-center">
        <FontAwesomeIcon icon={tableOfContentsIcon} fixedWidth />
        <h1>Table of contents</h1>
      </div>
      {eligibleBlocks.map((block, index) => (
        <button
          type="button"
          className="flex my-1 p-2 rounded text-sm font-bold bg-regularButtonBackgroundColor text-textColor w-full"
          key={block.uuid}
        >
          <div
            className={`text-left text-xs flex-grow ${isHeader(block) ? "italic pl-2 font-normal" : "font-bold"} ${isSelected(block) ? "text-mainTintColor" : ""}`}
          >
            {getTitle(block)}
          </div>
          <div className="flex items-center float-right">
            <FontAwesomeIcon
              icon={selectIcon}
              fixedWidth
              onClick={(e) => {
                handleSectionSelect(index, e.shiftKey);
                handleGoToBlock(block.uuid);
              }}
            />
            <FontAwesomeIcon
              icon={goToIcon}
              fixedWidth
              onClick={() => handleGoToBlock(block.uuid)}
            />
          </div>
        </button>
      ))}
    </div>
  );
};
