import React, { forwardRef, useState } from "react";

import { faSearchLocation as previewIcon } from "@fortawesome/free-solid-svg-icons/faSearchLocation";
import { faUser as travelerIcon } from "@fortawesome/free-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useProfile } from "@origin-dot/components";
import { Timestamp } from "firebase/firestore";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { Configure, InstantSearch } from "react-instantsearch";
import Popup from "reactjs-popup";
import { firestore } from "../../firebase";
import { getLocalTime } from "../../helpers/time";
import { useAlgolia } from "../../hooks/useAlgolia";
import { ActionButton } from "../ActionButton";
import { AutoComplete } from "../AutoComplete";
import { Image } from "../Image";
import { TripSearchResult } from "../trip/TripSearchResult";
import { UserSearchResult } from "../user/UserSearchResult";
import { DateTimePicker } from "../wysiwyg/DateTimePicker";

const SearchContainer = forwardRef(function SearchContainer(
  { isEmpty, children, ...props },
  ref,
) {
  return (
    <div
      ref={ref}
      className={
        "mt-1 shadow bg-cardBackgroundColor rounded-lg overflow-hidden"
      }
      {...props}
    >
      {children}
    </div>
  );
});

const SearchResult = ({ data, isHighlighted }) => {
  const ResultComponent = (() => {
    switch (data.indexType) {
      case "user":
        return UserSearchResult;
      case "trip":
        return TripSearchResult;
      default:
        return null;
    }
  })();

  if (!ResultComponent) return null;

  return (
    <div
      key={data.objectID}
      className={`cursor-pointer ${isHighlighted ? "bg-dividerColor highlighted" : ""}`}
    >
      <ResultComponent data={data} isHighlighted={isHighlighted} />
    </div>
  );
};

const UserFirstName = ({ userId, ...props }) => {
  const profile = useProfile(userId);
  return <span {...props}>{profile?.displayName}</span>;
};

const NewSupplierPaymentPopup = () => {
  const algolia = useAlgolia();

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("My Supplier Payment");
  const [tripId, setTripId] = useState();
  const [tripSuggestion, setTripSuggestion] = useState();
  const [tripRequestId, setTripRequestId] = useState();
  const [curatorTripId, setCuratorTripId] = useState("TBD1289FILL");
  const [dueDate, setDueDate] = useState(
    Timestamp.fromDate(new Date(Date.now() + 15 * 24 * 60 * 60000)),
  );

  const startTime =
    tripSuggestion?.startDate &&
    getLocalTime({
      date: new Date(`${tripSuggestion.startDate}T12:00:00`),
      timeZone: "Europe/Amsterdam",
    });

  const endTime =
    tripSuggestion?.endDate &&
    getLocalTime({
      date: new Date(`${tripSuggestion.endDate}T12:00:00`),
      timeZone: "Europe/Amsterdam",
    });
  const range =
    startTime && endTime && startTime.twix(endTime, { allDay: true });

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="w-full">
      <button
        type="button"
        className="bg-cardBackgroundColor rounded-lg border-4 border-cardBackgroundColor p-3 text-sm text-center w-full"
        onClick={handleOpen}
      >
        <div className="font-bold">
          Click Here To Create New Supplier Payment
        </div>
      </button>
      <Popup
        open={isOpen}
        onClose={handleClose}
        closeOnDocumentClick
        closeOnEscape
        modal
        overlayStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          // backdropFilter: 'blur(4px)',
          // WebkitBackdropFilter: 'blur(4px)',
        }}
        contentStyle={{
          backgroundColor: "var(--cardBackgroundColor)",
          borderWidth: 0,
          borderRadius: "0.5rem",
          maxWidth: "50rem",
          padding: "1rem",
        }}
      >
        <>
          <h3 className="text-lg">Associated Trip:</h3>
          {tripSuggestion && (
            <div className="flex p-2 items-center">
              <Image
                path={tripSuggestion.image}
                aspect={343 / 412}
                className="w-10 rounded bg-regularButtonBackgroundColor"
              />
              <div className="ml-2 truncate">
                <div className="text-sm font-semibold truncate flex items-center">
                  <span className="ais-Highlight">{tripSuggestion.title}</span>
                  <span
                    className="ml-1 rounded p-1 bg-mainTintColor text-white leading-none"
                    style={{ fontSize: "0.6rem" }}
                  >
                    {tripSuggestion.status}
                  </span>
                </div>
                <div className="text-xs truncate">
                  {range?.format({ monthFormat: "MMMM", implicitYear: false })}
                </div>
                <div className="text-xs">
                  {(tripSuggestion.users || []).map((userId) => (
                    <React.Fragment key={userId}>
                      <FontAwesomeIcon
                        icon={travelerIcon}
                        size="xs"
                        className="text-textDimmedColor mr-1"
                      />
                      <UserFirstName
                        key={userId}
                        userId={userId}
                        className="mr-1"
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="relative z-40 mt-3">
            <InstantSearch
              searchClient={algolia}
              indexName={`${process.env.ALGOLIA_INDEX_PREFIX}admin_universal`}
              future={{
                preserveSharedStateOnUnmount: true,
              }}
            >
              <Configure filters="indexType:trip" hitsPerPage={10} />
              <AutoComplete
                className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg py-2 pl-10 pr-2 block w-full appearance-none leading-normal ds-input"
                placeholder="Select associated trip"
                ResultComponent={SearchResult}
                ContainerComponent={SearchContainer}
                onSelect={(suggestion) => {
                  const splitSourceId = suggestion.objectID.split(":");
                  if (splitSourceId.length !== 3)
                    throw new Error(
                      "Error please retry or contact eng; Details: id split length",
                    );

                  setTripSuggestion(suggestion);
                  setTripRequestId(splitSourceId[1]);
                  setTripId(splitSourceId[2]);
                }}
              />
            </InstantSearch>
            <div className="pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center">
              <FontAwesomeIcon
                icon={previewIcon}
                className="text-textDimmedColor"
              />
            </div>
          </div>
          <h3 className="text-lg">Scenset Trip Id:</h3>
          <input
            className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg px-2 py-1 w-full"
            type="text"
            value={curatorTripId}
            placeholder="Trip Fee Amount"
            onChange={(e) => setCuratorTripId(e.target.value)}
          />
          <h1 className="text-2xl">Supplier Payment</h1>
          <h3 className="text-lg">Title:</h3>
          <input
            className="bg-chatInputBackgroundColor placeholder-textDimmedColor border border-searchBorderColor rounded-lg px-2 py-1 w-full"
            type="text"
            value={title}
            placeholder="Trip Fee Amount"
            onChange={(e) => setTitle(e.target.value)}
          />
          <h3 className="text-lg">Due Date in UTC:</h3>
          <DateTimePicker
            value={{
              date: dueDate,
              timeZone: "UTC",
            }}
            onChange={(dueDateWithTimezone) => {
              setDueDate(dueDateWithTimezone.date);
            }}
          />
          <ActionButton
            className="mt-3"
            onAction={async () => {
              if (
                [title, tripId, tripRequestId, curatorTripId, dueDate].filter(
                  (x) => !x,
                ).length > 0
              ) {
                // eslint-disable-next-line no-alert
                alert("Please fill in all the information");
                return;
              }
              // eslint-disable-next-line no-alert
              alert(
                "Please wait for the alert saying the supplier payment has been created before clicking this again!\nIt takes a while\nif this bugs you bug Nour",
              );
              try {
                const supplierPaymentRef = doc(
                  collection(firestore, "supplierPayments"),
                );
                await setDoc(supplierPaymentRef, {
                  createdAt: serverTimestamp(),
                  lastModified: serverTimestamp(),
                  status: "OPEN",
                  title,
                  tripId,
                  tripRequestId,
                  curatorTripId,
                  dueDate,
                });
              } catch (err) {
                // eslint-disable-next-line no-alert
                alert(err.message);
              }
              // eslint-disable-next-line no-alert
              alert("A supplier payment has been created yay!");
            }}
          >
            Create Supplier Payment
          </ActionButton>
        </>
      </Popup>
    </div>
  );
};

export const SupplierPaymentListing = ({
  supplierPayments,
  selectedSupplierPaymentId,
  onSelectPaymentId,
}) => {
  return (
    <div
      className="flex-grow p-3 overflow-y-auto grid content-start gap-2"
      onClick={() => onSelectPaymentId(null)}
    >
      {supplierPayments && <NewSupplierPaymentPopup />}
      {supplierPayments?.map((payment) => {
        const paymentId = payment.id;
        return (
          <button
            type="button"
            key={paymentId}
            onClick={(event) => {
              event.stopPropagation();
              onSelectPaymentId(
                selectedSupplierPaymentId === paymentId ? null : paymentId,
              );
            }}
            className={`text-left p-2 bg-cardBackgroundColor border-2 rounded-lg ${
              selectedSupplierPaymentId === paymentId
                ? "border-mainTintColor"
                : "border-cardBackgroundColor"
            }`}
          >
            <div className="mb-2 flex flex-col text-xs items-stretch">
              <div className="flex items-center">
                <div className="font-bold text-sm flex-grow truncate">
                  Status: {payment.status}
                </div>
                <div className="font-bold text-sm flex-grow truncate text-right">
                  Due Date:{" "}
                  {getLocalTime({ date: payment.dueDate.toDate() }).format(
                    "YYYY-MM-DD HH:mm",
                  )}
                </div>
              </div>
              <div className="flex items-center">
                <div className="font-bold text-sm flex-grow truncate text-center">
                  {payment.title || ""}
                </div>
              </div>
              <div className="truncate">
                Last modified:{" "}
                {getLocalTime({
                  date: payment.lastModified.toDate(),
                }).fromNow()}
              </div>
            </div>
            <div />
          </button>
        );
      })}
    </div>
  );
};
